<template>
  <div v-if="!loading" class="col">
    <div class="row justify-center">
      <div class="col col-md-12">
        <div class="row q-col-gutter-x-md">
          <div class="col-24 col-md-12">
            <label>Логика запросов поставщику данных</label>
            <q-checkbox
              v-model="rosreestrCheckRequestsLimits"
              label="С ограничением"
            />
          </div>

          <div class="col-24">
            <q-btn
              :loading="awaitUpdating"
              :disable="awaitUpdating"
              label="Сохранить"
              color="primary"
              class="q-mt-md"
              @click="save"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import api from "@/api";
  import { createMetaMixin } from "quasar";

  export default {
    name: "CadastralsSettings",

    mixins: [
      createMetaMixin(function () {
        return {
          title: "Настройки Базы кадастров",
        };
      }),
    ],

    async mounted() {
      await this.setSettings();
      this.loading = false;
    },

    data() {
      return {
        loading: true,
        awaitUpdating: false,
        rosreestrCheckRequestsLimits: null,
      };
    },

    computed: {},

    methods: {
      async setSettings() {
        const res = await api.settings.findCadastrals();
        this.rosreestrCheckRequestsLimits =
          res.data.rosreestr_check_requests_limits;

        this.loading = false;
      },

      async save() {
        let payload = {
          rosreestr_check_requests_limits: this.rosreestrCheckRequestsLimits,
        };

        this.awaitUpdating = true;

        return api.settings
          .updateCadastrals(payload)
          .then(
            (res) => {
              this.$q.notify({
                color: "positive",
                message: res.data.message,
              });
            },
            (error) => {
              this.$q.notify({
                color: "negative",
                message: error.response.data.message,
              });
            }
          )
          .then(() => {
            this.awaitUpdating = false;
          });
      },
    },
  };
</script>
